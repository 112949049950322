:root {
  /* Primary color */
  --color-primary-default: #1f4e88;
  --color-primary-light: #2679bd;
  --color-primary-dark: #14345b;
  --color-primary-ultralight: #96beee;
  /* ... */
  --color-primary-blue: #4565f6;
  --color-primary-grey: #a6a9c8;
  --color-primary-grey-title: #827f9b;
  --color-primary-grey-background: #f5f5f9;
  /* Secondary color */
  --color-secondary-lila: #8f9dff;
  --color-secondary-blueGreenPastel: #d5f0f6;
  --color-secondary-topePink: #e4d9e6;
  --color-secondary-pastelPink: #ffccd2;
  --color-secondary-yellowLemon: #fff7ab;
  --color-secondary-abricot: #ffe2c8;
  --color-secondary-greenOnline: #34c7a4;
  /* CUSTOM DELETE */
  --color-delete: #ef4444;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(69, 101, 246, 0.3);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(69, 101, 246, 0.5);
}

.react-time-picker__clear-button,
.react-time-picker__clock-button,
.react-time-picker__clock,
.react-time-picker__clock--open {
  display: none !important;
}
.react-time-picker__wrapper {
  border: none !important;
}
.react-time-picker {
  color: #4565f6 !important;
  background: #ffffff !important;
  border-radius: 10px !important;
  width: 47px !important;
  height: 37px !important;
  font-weight: bold !important;
  font-size: 13px !important;
  line-height: 15px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.consultationsDetails {
  font-size: 16px;
  font-weight: bold;
  margin: 16px 13px 0px 13px;
  padding-bottom: 19px;
  border-bottom: 1px solid rgba(166, 169, 200, 0.3);
  ul {
    padding-left: 24px;
    li {
      list-style-type: disc;
      div {
        line-height: 24px;
      }
    }
  }
}

.containerSites {
  padding-right: 23px;
  padding-left: 23px;
  background-color: white;
  .boxSite {
    border-bottom: 1px solid rgba(166, 169, 200, 0.3);
    padding-bottom: 14px;
    padding-top: 14px;
    &:last-child {
      border-bottom: none;
    }
    img {
      // width: 16px;
      // height: 24px;
      margin-right: 21px;
    }
    p {
      font-size: 16px;
      font-weight: 700;
      margin-left: 21px;
    }
    .svgContainer {
      min-width: 23px;
      min-height: 23px;
    }
  }
}

.cursusAdd {
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;

  .form-cursus {
    div:nth-child(even) {
      margin-left: 10px;
      margin-right: 10px;
      @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-left: 0;
      }
    }
    .buttons {
      button {
        min-width: 40px;
        &:nth-child(1) {
          margin-right: 10px;
        }
      }
    }
  }
  .check {
    font-size: 16px;
    line-height: 16px;
    color: #a6a9c8;
    &:first-child {
      margin-left: 6px;
    }
  }
}

.new-event-appointment-form {
  > .button-group-wrapper {
    width: 298px;
    height: 18.1px;
    flex-grow: 0;
    margin: 0 0 4.9px;
    padding: 0 221px 0.1px 0;

    button {
      width: 8em;
      padding: 0.3em;
      flex-grow: 0;
      margin: 0 31px 0.1px 0;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #a6a9c8;
      background-color: transparent;
    }

    button.active {
      color: #4565f6;
      border-bottom: 1px solid #4565f6;
    }
  }
  input[type="text"] {
    width: 417px;
    height: 45px;
    padding: 10px 10px 11.5px 37px;
    border-radius: 9px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(166, 169, 200, 0.28);
  }
}

.agenda-config-form {
  padding-top: 20.9px;

  h1 {
    text-align: center;
  }

  > .button-group-wrapper {
    flex-grow: 0;
    margin: 1.4em 2.6em;
    padding: 0.25em;
    border-radius: 1em;
    border: solid 1px #eaecf4;
    background-color: #fff;

    button {
      width: 9.5em;
      height: 2.9em;
      flex-grow: 0;
      margin: 0;
      //margin: 0 17.2px 0 0;
      //padding: 12px 19.8px 12px 19.8px;
      border-radius: 15px;
      background-color: transparent;
      color: #a6a9c8;
      @media screen and (max-width: 992px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    button.active {
      background-color: #f5f5f9;
      color: #4565f6;
    }
  }
  .workingDays {
    margin-top: 10px;
    .day {
      max-height: 35px !important;
    }
  }
  .plageHoraire {
    margin-top: 32px;
    .sub-values {
      // margin-right: 50px;
      // margin-top: 8px;
      .value {
        background: #ffffff;
        border-radius: 10px;
        width: 47px;
        height: 37px;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        color: #4565f6;
        margin-left: 6px;
        margin-right: 6px;
      }
    }
  }
  select {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(166, 169, 200, 0.28);
    box-sizing: border-box;
    border-radius: 9px;
    width: 109px;
    height: 45px;
  }
  .checkboxCont {
    margin-top: 62px;
    margin-bottom: 27px;
  }
  .rdvTimeContainer {
    margin-top: 82px;
  }
  .dashedButton {
    cursor: pointer;
    margin-top: 55px;
    border: 1px dashed var(--color-primary-blue);
    box-sizing: border-box;
    border-radius: 9px;
    // width: 415px;
    padding-left: 30px;
    padding-right: 30px;

    .menu {
      color: var(--color-primary-blue);
      padding: 15px 0;
    }
  }
}

.disabled-box {
  flex-grow: 0;
  padding: 10px 12px 9px 14px;
  border-radius: 9px;
  background-color: rgba(166, 169, 200, 0.22);
  font-size: 14px;
  color: var(--color-primary-grey);
}
.active-box {
  flex-grow: 0;
  padding: 10px 12px 9px 14px;
  border-radius: 9px;
  background-color: var(--color-primary-blue);
  font-size: 14px;
  color: var(--color-primary-grey-background);
}

.env {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: url("#goo");
}

.env-btn {
  z-index: 99;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background: linear-gradient(45deg, #83eaf1, #63a4ff);
  border-radius: 20px;
  color: #fff;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 200;
}

.env-icon {
  opacity: 0;
  position: relative;
  margin: 8px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: #fff;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    margin-top: 10px;
  }
}

.local {
  background: #83eaf1;
}

.staging {
  background: #63a4ff;
}

.prod {
  background: #045de9;
}

.clicked {
  opacity: 1;
  transition: 1.2s all ease;
  transform: translateY(56px);
}

.h-90 {
  height: 90vh !important;
}

/**
* palier
*/

/** ========================
 * Contenedor
 ============================*/
.pricing-wrapper {
  background: #fff;
  color: #fff;
  font-size: 62.5%;
  font-family: "Roboto", Arial, Helvetica, Sans-serif, Verdana;
  * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  ul {
    list-style-type: none;
  }
}

.pricing-table {
  margin: 0 10px;
  text-align: center;
  width: 300px;
  float: left;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.pricing-table:hover {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  -o-transform: scale(1.06);
  transform: scale(1.06);
}

.pricing-title {
  color: #fff;
  background: #e95846;
  padding: 20px 0;
  font-size: 2em;
  text-transform: uppercase;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

.pricing-table.recommended .pricing-title {
  background: #2db3cb;
}

.pricing-table.recommended .pricing-action {
  background: #2db3cb;
}

.pricing-table .price {
  background: #403e3d;
  font-size: 3.4em;
  font-weight: 700;
  padding: 20px 0;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

.pricing-table .price sup {
  font-size: 0.4em;
  position: relative;
  left: 5px;
}

.table-list {
  background: #fff;
  color: #403d3a;
}

.table-list li {
  font-size: 1.4em;
  font-weight: 700;
  padding: 12px 8px;
}

.table-list li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #3fab91;
  display: inline-block;
  position: relative;
  right: 5px;
  font-size: 16px;
}

.table-list li span {
  font-weight: 400;
}

.table-list li span.unlimited {
  color: #fff;
  background: #e95846;
  font-size: 0.9em;
  padding: 5px 7px;
  display: inline-block;
  -webkit-border-radius: 38px;
  -moz-border-radius: 38px;
  border-radius: 38px;
}

.table-list li:nth-child(2n) {
  background: #f0f0f0;
}

.table-buy {
  background: #fff;
  padding: 15px;
  text-align: left;
  overflow: hidden;
}

.table-buy p {
  float: left;
  color: #37353a;
  font-weight: 700;
  font-size: 2.4em;
}

.table-buy p sup {
  font-size: 0.5em;
  position: relative;
  left: 5px;
}

.table-buy .pricing-action {
  float: right;
  color: #fff;
  background: #e95846;
  padding: 10px 16px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-weight: 700;
  font-size: 1.4em;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.table-buy .pricing-action:hover {
  background: #cf4f3e;
}

.recommended .table-buy .pricing-action:hover {
  background: #228799;
}

/** ================
 * Responsive
 ===================*/
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .pricing-wrapper {
    width: 768px;
  }

  .pricing-table {
    width: 236px;
  }

  .table-list li {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-wrapper {
    width: 420px;
  }

  .pricing-table {
    display: block;
    float: none;
    margin: 0 0 20px 0;
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .pricing-wrapper {
    width: 300px;
  }
}
